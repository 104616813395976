module.exports = [{
      plugin: require('../../../var/task/node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"ytl-website","promptForAccessToken":true,"apiEndpoint":"https://ytl-website.cdn.prismic.io/api/v2","lang":"*","imageImgixParams":{"auto":"compress,format","fit":"max","q":50},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../../../var/task/node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../var/task/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"static/favicon-32x32.png","icons":[{"src":"static/android-chrome-192x192.png","sizes":"192x192","type":"images/png"},{"src":"static/favicon.png","sizes":"512x512","type":"images/png"}],"theme_color":"#e58061","background_color":"#f7ede8","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"dd0638980e5471bbfdae0fdae593d7f6"},
    },{
      plugin: require('../../../var/task/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/tmp/app/src/components/layout/index.js"},
    },{
      plugin: require('../../../var/task/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WBMKPXH","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
