import React from 'react';

export const IconFacebook = () => (
  <svg width="20" height="20" viewBox="0 0 20 20">
    <defs>
      <path id="facebooka" d="M0 0h20v20H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <mask id="facebookb" fill="#fff">
          <use xlinkHref="#facebooka" />
        </mask>
        <path d="M10 20c5.523 0 10-4.477 10-10 0-5.522-4.477-10-10-10S0 4.478 0 10c0 5.523 4.477 10 10 10" fill="#E58061" mask="url(#facebookb)" />
      </g>
      <path d="M7.588 8.307H8.62V7.302c0-.443.011-1.127.334-1.55.338-.447.804-.753 1.604-.753 1.304 0 1.853.187 1.853.187l-.258 1.532s-.43-.125-.833-.125c-.401 0-.762.144-.762.546v1.168h1.649l-.115 1.496H10.56V15H8.621V9.803H7.588V8.307z" fill="#FFF" />
    </g>
  </svg>
);

export const IconTwitter = () => (
  <svg width="20" height="20" viewBox="0 0 20 20">
    <defs>
      <path id="twittera" d="M0 0h20v20H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <mask id="twitterb" fill="#fff">
          <use xlinkHref="#twittera" />
        </mask>
        <path d="M10 20c5.523 0 10-4.477 10-10 0-5.522-4.477-10-10-10S0 4.478 0 10c0 5.523 4.477 10 10 10" fill="#E58061" mask="url(#twitterb)" />
      </g>
      <path d="M15.017 7.38a4.098 4.098 0 0 1-1.18.324c.424-.254.75-.657.903-1.137a4.087 4.087 0 0 1-1.305.498A2.055 2.055 0 0 0 9.934 8.94a5.833 5.833 0 0 1-4.237-2.147 2.054 2.054 0 0 0 .636 2.745 2.054 2.054 0 0 1-.931-.257v.026c0 .996.709 1.826 1.65 2.014a2.01 2.01 0 0 1-.93.036 2.054 2.054 0 0 0 1.92 1.427A4.124 4.124 0 0 1 5 13.635c.91.582 1.99.922 3.15.922 3.78 0 5.847-3.13 5.847-5.846 0-.09-.001-.18-.005-.266.401-.29.75-.652 1.025-1.064" fill="#FFF" />
    </g>
  </svg>
);

export const IconLinkedIn = () => (
  <svg width="20" height="20" viewBox="0 0 20 20">
    <defs>
      <path id="linkedina" d="M0 0h20v20H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <mask id="linkedinb" fill="#fff">
          <use xlinkHref="#linkedina" />
        </mask>
        <path d="M10 20c5.524 0 10-4.477 10-10S15.524 0 10 0C4.477 0 0 4.477 0 10s4.477 10 10 10" fill="#E58061" mask="url(#b)" />
      </g>
      <path d="M15 14.183h-2.21v-3.228c0-.845-.346-1.422-1.106-1.422-.582 0-.905.39-1.056.764-.056.135-.047.322-.047.51v3.376H8.39s.028-5.718 0-6.238h2.19v.979c.13-.428.829-1.04 1.946-1.04 1.385 0 2.473.898 2.473 2.83v3.469zM6.177 7.165h-.014C5.457 7.165 5 6.688 5 6.083 5 5.467 5.47 5 6.19 5c.72 0 1.162.466 1.176 1.082 0 .604-.456 1.083-1.189 1.083zm-.925 7.018h1.95V7.945h-1.95v6.238z" fill="#FFF" />
    </g>
  </svg>
);

export function IconPrint () {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20">
      <g transform="translate(.034)" fill="none" fillRule="evenodd">
        <path d="M10 0C4.489 0 0 4.467 0 10s4.489 10 10 10 10-4.467 10-10S15.511 0 10 0z" fill="#E58061" fillRule="nonzero" />
        <rect fill="#FFF" x="3.966" y="8" width="12" height="5" rx="1" />
        <path fill="#FFF" d="M6.966 4h6v3h-6z" />
        <path stroke="#E58061" fill="#FFF" d="M6.466 11.5h7v5h-7z" />
        <path stroke="#E58061" strokeWidth=".5" strokeLinecap="square" d="M8.466 13.5h3M8.466 14.5h2" />
      </g>
    </svg>
  );
}

export const IconInstagram = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <path id="olu7s68w3a" d="M0 0h10v10H0z"/>
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle fill="#E58061" cx="10" cy="10" r="10"/>
      <path d="M12.647 6.786a.586.586 0 1 0 0 1.172.586.586 0 0 0 0-1.172M10.041 11.578A1.58 1.58 0 0 1 8.464 10c0-.87.707-1.577 1.577-1.577S11.62 9.13 11.62 10a1.58 1.58 0 0 1-1.578 1.578m0-4.04A2.465 2.465 0 0 0 7.578 10a2.465 2.465 0 0 0 2.463 2.463A2.465 2.465 0 0 0 12.504 10a2.465 2.465 0 0 0-2.463-2.463" fill="#FFF"/>
      <g transform="translate(5 5)">
        <mask id="pdr2fcw6ub" fill="#fff">
          <use xlinkHref="#olu7s68w3a"/>
        </mask>
        <path d="M3.004.94A2.065 2.065 0 0 0 .94 3.005v3.992c0 1.138.925 2.063 2.063 2.063h3.992A2.065 2.065 0 0 0 9.06 6.996V3.004A2.065 2.065 0 0 0 6.996.94H3.004zM6.996 10H3.004A3.007 3.007 0 0 1 0 6.996V3.004A3.007 3.007 0 0 1 3.004 0h3.992A3.007 3.007 0 0 1 10 3.004v3.992A3.007 3.007 0 0 1 6.996 10z" fill="#FFF" mask="url(#pdr2fcw6ub)"/>
      </g>
    </g>
  </svg>
);

export const IconPinterest = () => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Icon-/-Pinteret" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group" fill="#E58061">
        <circle id="Oval-Copy" cx="10" cy="10" r="10"></circle>
      </g>
      <path d="M9.23804084,11.5231745 C9.22949037,11.5519041 9.22162394,11.5761874 9.2154676,11.6004708 C8.84813947,13.0396856 8.80743924,13.3591312 8.42985054,14.0274358 C8.24994868,14.3451712 8.04713157,14.6461477 7.82276727,14.9341275 C7.79745788,14.9666193 7.77385859,15.0086876 7.72323981,14.998427 C7.66783278,14.9867984 7.66338653,14.9368636 7.65757221,14.8927432 C7.5970349,14.4553012 7.56385908,14.0168332 7.57822387,13.575287 C7.59737692,13.0000115 7.66851681,12.8026667 8.41001345,9.68516579 C8.42061604,9.63762519 8.40864538,9.59795101 8.3925705,9.55519867 C8.21540479,9.07637243 8.18017686,8.59070581 8.33511135,8.09819881 C8.67063174,7.03486254 9.87624782,6.95312006 10.0872734,7.83074016 C10.2168985,8.37318189 9.87351167,9.08355482 9.60981521,10.1332103 C9.39126523,10.9995438 10.4108231,11.6151776 11.2822869,10.9827849 C12.0856889,10.399985 12.39761,9.00249638 12.3384408,8.01201009 C12.2214704,6.03753587 10.0564917,5.61069648 8.68260239,6.24650933 C7.10760607,6.97466724 6.74985446,8.92691023 7.46091144,9.81889512 C7.55086237,9.93244535 7.62029217,10.0018752 7.59053654,10.1167934 C7.54470603,10.2956693 7.50434782,10.4755711 7.45509712,10.6530788 C7.41850111,10.7854401 7.30768704,10.8333227 7.17395771,10.7792838 C6.91197135,10.6732579 6.69376339,10.5060108 6.51659768,10.2864347 C5.91259258,9.53878177 5.73987311,8.0595507 6.53814486,6.80742007 C7.42260534,5.42019204 9.06805752,4.8592813 10.5702038,5.02960663 C12.3644342,5.2334498 13.4985683,6.45924499 13.710962,7.85023522 C13.8077533,8.48365394 13.7379815,10.0459956 12.8487327,11.1500321 C11.8254126,12.4189216 10.1669637,12.5030582 9.40186782,11.7242816 C9.34304059,11.6644283 9.295842,11.5946564 9.23804084,11.5231745" id="Fill-1" fill="#FFFFFF"></path>
    </g>
  </svg>
);
