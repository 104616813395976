import { useMergePrismicPreviewData as useMergePrismicPreviewDataOrig }
  from 'gatsby-plugin-prismic-previews';

/*
 * Not sure why, but `useMergePrismicPreviewDataFix` returns the result wrapped in `{ data: ... }`.
 * This checks for that and returns data properly in this case.
 */
export default function useMergePrismicPreviewData (...args) {
  const result = useMergePrismicPreviewDataOrig(...args);

  if (result.data) return result.data;
  return result;
}
