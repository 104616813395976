import React from 'react';
import { Link } from 'gatsby';

export const LinkWrapper = ({ url, children, target, ...rest }) => {
  if (!url) return <>{children}</>;

  if (/^(([a-z0-9]+:\/\/)|#)/i.test(url)) {
    return (
      <a href={url} {...rest} target={target}>
        { children }
      </a>
    );
  }

  return (
    <Link to={url} {...rest}>
      {children}
    </Link>
  );
};
