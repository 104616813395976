/* eslint-env browser */
import React, { useContext, useEffect } from 'react';
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import { PrismicPreviewProvider, PrismicContext } from 'gatsby-plugin-prismic-previews';
import markerSDK from '@marker.io/browser';
import { AuthContextProvider } from './src/components/auth';
import 'gatsby-plugin-prismic-previews/dist/styles.css';

function MarkerIoLoader ({ destination }) {
  const [ context ] = useContext(PrismicContext);

  useEffect(() => {
    if (!destination) return;

    let isOn = process.env.GATSBY_MARKER_IO_CONDITION === 'always';

    if (!isOn && process.env.GATSBY_MARKER_IO_CONDITION === 'prismic-preview') {
      isOn = context.previewState === 'ACTIVE';
    }

    if (isOn) {
      markerSDK.loadWidget({
        destination
      }).then((widget) => {
        window.markerWidget = widget;
      });

      return () => {
        if (!window.markerWidget) return;
        window.markerWidget.unload();
      };
    }
  }, [ destination, context ]);

  return null;
}


export const wrapRootElement = ({ element }) => {
  return (
    <PrismicPreviewProvider>
      <MarkerIoLoader
        PrismicContext={PrismicContext}
        destination={process.env.GATSBY_MARKER_IO}
      />
      <AuthContextProvider>
        {element}
      </AuthContextProvider>
    </PrismicPreviewProvider>
  );
};

const amplifyConfigs = {};

if (process.env.GATSBY_COOKIE_DOMAIN) {
  amplifyConfigs.cookieStorage = {
    domain: process.env.GATSBY_COOKIE_DOMAIN
  };
}

Amplify.configure({
  ...amplifyConfigs,
  'aws_cognito_region': 'us-east-1',
  'aws_user_pools_id': process.env.GATSBY_AWS_USER_POOLS_ID,
  'aws_user_pools_web_client_id': process.env.GATSBY_AWS_USER_POOLS_WEB_CLIENT_ID,
  'aws_cognito_identity_pool_id': process.env.GATSBY_AWS_COGNITO_IDENTITY_POOL_ID,
  'aws_mandatory_sign_in': true,
  API: {
    endpoints: [
      {
        name: "sundara-ytl-api",
        endpoint: process.env.GATSBY_API_ENDPOINT,
        'custom_header': async () => {
          let token = (await Auth.currentSession()).getIdToken().getJwtToken();
          return { Authorization: `Bearer ${token}` };
        }
      }
    ]
  }
});

if (process.env.GATSBY_NODE_ENV === 'development') {
  window.devSignIn = (...args) => {
    return Amplify.Auth.signIn(...args).then((user) => {
      console.log(user);
      window.location.reload();
    }, console.error);
  };

  window.devSignOut = () => {
    return Amplify.Auth.signOut().then(() => {
      window.location.reload();
    }, console.error);
  };
}
