import React, { forwardRef } from 'react';
import { TextField, InputAdornment } from '@material-ui/core';
import './styles.scss';

const TextInput = forwardRef(({
  handleChange,
  value,
  label,
  required = true,
  endAdornment,
  InputProps = {},
  ...props
}, ref) => {
  if (endAdornment) {
    InputProps.endAdornment = (
      <InputAdornment position="end">{endAdornment}</InputAdornment>
    );
  }

  return (
    <TextField
      required={required}
      variant="filled"
      label={label}
      onChange={handleChange}
      value={value}
      ref={ref}
      InputProps={InputProps}
      { ...props }
    />
  );
});

export default TextInput;
