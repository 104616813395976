import React from 'react';

export function IconSubscriberGate () {
  return (
    <svg width="28" height="16" viewBox="0 0 28 16" xmlns="http://www.w3.org/2000/svg">
      <g fill="#E58061" fillRule="evenodd">
        <g fillRule="nonzero">
          <path d="M14.23 13.406c.287-1.123 1.002-2.581 2.771-4.113a10.012 10.012 0 0 1-.639 2.575 6.82 6.82 0 0 1-2.312 2.99c.002-.49.063-.977.18-1.452zM9.346 9.021a.021.021 0 0 1 .01.008l.033.027c.038.547.118 1.09.237 1.625.035.164.07.318.113.48.085.337.189.668.312.992.151.398.342.779.57 1.138.614.95 1.07 1.433 2.08 2.03-1.993-.266-4.895-2.35-5.987-3.771A7.76 7.76 0 0 1 5.04 7.34c.762.127 1.512.317 2.243.568a6.36 6.36 0 0 1 2.064 1.113zM25.844 7.218l.764.093c0 1.692-.125 5.534-5.124 7.87-1.753.82-5.212.93-7.05.737.78-.253 1.54-.564 2.276-.928 5.828-2.912 5.666-7.679 5.666-7.679l.925-.105a10.76 10.76 0 0 1 2.543.012zM21.968 6.165c0 .226-.008.453-.028.676-.82.06-1.64.328-2.407.598a5.834 5.834 0 0 0-2.04 1.184c.077-1.448-.03-2.881-.492-4.2 1.268-1.149 2.765-1.773 4.337-2.162 0 0 .63 1.974.63 3.904z" />
          <path d="M21.92 7.311a8.11 8.11 0 0 1-1.654 4.316c-.751.983-2.196 2.23-3.729 2.98a6.203 6.203 0 0 1-2.088.645c.616-.54 1.244-.984 1.785-1.977.221-.404.416-.82.584-1.248.163-.415.294-.841.392-1.276.123-.523.208-1.055.252-1.59h.012a6.595 6.595 0 0 1 2.131-1.21 15.055 15.055 0 0 1 2.315-.64zM16.567 4.553C17 5.93 17.148 7.381 17 8.816c-1.311.82-2.107 2.145-2.56 2.873.11-1.038-.022-2.068-.532-3.363-.028-.069-.054-.138-.084-.207l.02-.037c.236-.445 1.215-2.278 2.722-3.529zM13.68 0c1.369 1.266 2.197 2.486 2.726 4.068-1.395 1.017-2.76 3.243-2.93 3.786a4.65 4.65 0 0 0-.093-.19c-.39-.745-1.594-2.717-2.665-3.526A10.021 10.021 0 0 1 13.68 0zM13.955 10.212c.14 1.04.075 2.1-.192 3.115-.187.702-.284 1.017-.284 1.017l-.413-1.027a5.862 5.862 0 0 1 .292-4.553l.036-.077c.04-.08.082-.164.122-.236.205.538.35 1.098.43 1.669 0 .03.006.062.009.092zM12.937 7.778c.096.164.164.286.201.358a6.52 6.52 0 0 0-.672 1.826c-.123.583-.233 1.065-.147 1.655-.54-.966-1.52-2.114-2.396-2.729-.085-1.133-.09-1.998.062-2.872a8.7 8.7 0 0 1 .417-1.532c.711.48 2.008 2.419 2.535 3.294zM12.63 13.422c.14.467.223.95.25 1.436a5.935 5.935 0 0 1-1.834-1.772 6.136 6.136 0 0 1-.542-1.05 7.906 7.906 0 0 1-.319-.968c-.124-.491-.212-.99-.262-1.494 1.397 1.344 2.296 2.494 2.707 3.848zM12.148 15.82a11.44 11.44 0 0 1-.741.055c-1.894.089-4.673-.125-6.281-.82C.513 13.053 0 8.595 0 7.185l1.067-.087c.82-.067 2.632-.085 3.506.136a8.54 8.54 0 0 0 .256 1.551l.05.185c.555 1.947 1.968 4.885 7.27 6.85zM9.951 4.355a9.595 9.595 0 0 0-.407 1.587c-.14.845-.186 1.703-.134 2.558-.56-.53-1.213-.79-1.93-1.04a9.577 9.577 0 0 0-2.4-.557 7.924 7.924 0 0 1-.028-.689c0-1.95.62-4.023.62-4.023 1.572.387 3.016 1.014 4.279 2.164z" />
        </g>
      </g>
    </svg>

  )
}