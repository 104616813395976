/*globals process*/
import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import { Link } from 'gatsby';
import { TextButton } from '../buttons';
import { Img } from '../image';
import { LinkWrapper } from '../link-wrapper';
import { IconHamburger } from './icons';
import { IconFacebook, IconLinkedIn, IconTwitter, IconClose, IconInstagram, IconPinterest }
  from '../icons';
import NewsletterSignup from '../newsletter-signup';
import { AuthContext } from '../auth';
import './styles.scss';

function fixProdEnv (url) {
  if (!process.env.GATSBY_PROD_ENV) return url;
  return url.replace('.yogatherapylibrary.com',
    `.${process.env.GATSBY_PROD_ENV}.yogatherapylibrary.com`);
}

const Header = ({
  logo,
  libraryNavItems,
  mainNavItems,
  extraFooterNav,
  facebookURL,
  twitterURL,
  linkedinURL,
  instagramURL,
  pinterestURL,
  privacyPolicyLink,
  termsOfServiceLink,
  copyright,
  subscribeLink
}) => {
  const auth = useContext(AuthContext);
  const [ mobileNavActive, setMobileNavActive ] = useState(false);

  useEffect(() => {
    if (mobileNavActive) {
      document.body.classList.add('nav-active');
    } else {
      document.body.classList.remove('nav-active');
    }

    return () => {
      document.body.classList.remove('nav-active');
    };
  }, [ mobileNavActive ]);

  const handleToggleClick = useCallback(() => {
    setMobileNavActive(!mobileNavActive);
  }, [ mobileNavActive ]);

  const closeMobileNav = () => setMobileNavActive(false);

  const libraryNavItemsRendered = useMemo(() => {
    if (!libraryNavItems.length) return null;

    return libraryNavItems.map((item, i) => {
      const {
        link,
        link_logged_in,
        text
      } = item;

      let url = link.url;

      if (auth.userData && link_logged_in?.url) url = link_logged_in.url;

      url = fixProdEnv(url);

      return (
        <li
          key={`library-nav-${i}`}
          className="sub-nav-list-item"
        >
          <LinkWrapper url={url} onClick={closeMobileNav}>
            {text.text}
          </LinkWrapper>
        </li>
      );
    });
  }, [ libraryNavItems, auth ]);

  const mainNavItemsRendered = useMemo(() => {
    if (!mainNavItems.length) return null;

    return mainNavItems.map((item, i) => {
      const {
        link,
        text
      } = item;

      return (
        <li
          key={`main-nav-${i}`}
          className="nav-list-item"
        >
          <LinkWrapper url={link.url} onClick={closeMobileNav} target={link.target}>
            {text.text}
          </LinkWrapper>
        </li>
      );
    });
  }, [ mainNavItems ]);

  const extraFooterNavItemsRendered = useMemo(() => {
    if (!extraFooterNav.length) return null;

    return extraFooterNav.map((item, i) => {
      const { link, text } = item;

      return (
        <li key={i} className="nav-list-item">
          <LinkWrapper url={link.url} onClick={closeMobileNav} target={link.target}>
            {text}
          </LinkWrapper>
        </li>
      );
    });
  }, [ extraFooterNav ]);

  const loginNavItemRendered = useMemo(() => {
    if (auth.userData) return null;

    return (
      <li className="nav-list-item">
        <LinkWrapper url={fixProdEnv(process.env.LOG_IN_URL)}>Log in</LinkWrapper>
      </li>
    );
  }, [ auth ]);

  const subscribeButton = useMemo(() => {
    if (auth.userData) {
      return (
        <ul className="nav-list">
          <li className="nav-list-item --with-subnav my-account">
            <TextButton onClick={e => e.preventDefault()}>My Account</TextButton>
            <ul className="sub-nav">
              <li className="sub-nav-list-item">
                <LinkWrapper url={fixProdEnv(process.env.SETTINGS_URL)}>Settings</LinkWrapper>
              </li>
              <li>
                <LinkWrapper url={fixProdEnv(process.env.BILLING_URL)}>Billing</LinkWrapper>
              </li>
              <li className="nav-list-item">
                <LinkWrapper url={fixProdEnv(process.env.LOG_OUT_URL)}>Log out</LinkWrapper>
              </li>
            </ul>
          </li>
        </ul>
      );
    }

    return (
      <TextButton to={subscribeLink.url} onClick={closeMobileNav}>Subscribe</TextButton>
    );
  }, [ subscribeLink, auth ]);

  return (
    <header className="site-header">
      <div className="site-header__inner container">
        <div className="site-header__col col-left">
          <Link
            to={'/'}
            className="logo"
            onClick={closeMobileNav}
            aria-label="Site Logo"
          >
            <Img image={logo} />
          </Link>
        </div>
        <div className="site-header__col col-right">
          <div
            className="mobile-toggle"
            role="button"
            tabIndex="0"
            onClick={handleToggleClick}
            onKeyDown={handleToggleClick}
            aria-label="Toggle Mobile Nav"
          >
            { mobileNavActive ? <IconClose /> : <IconHamburger />}
          </div>
          <div className={`green-bg ${mobileNavActive ? 'active' : ''}`} />
          <nav className={`site-nav ${mobileNavActive ? 'active' : ''}`}>
            <div className="nav-list-container">
              <ul className="nav-list">
                <li className="nav-list-item --with-subnav">
                  <a href="/" onClick={closeMobileNav}>Library</a>
                  <ul className="sub-nav">
                    { libraryNavItemsRendered }
                  </ul>
                </li>
                { mainNavItemsRendered }
                { extraFooterNavItemsRendered }
                { loginNavItemRendered }
              </ul>
              {subscribeButton}
            </div>
            <div className="nav-sign-up">
              <NewsletterSignup
                buttonText={{ text: "Join Our Mailing List" }}
                id="header-newsletter-signup"
              />
              <div className="social-buttons">
                {
                  facebookURL?.url ? (
                    <LinkWrapper target="_blank" url={facebookURL.url} aria-label="Facebook">
                      <IconFacebook />
                    </LinkWrapper>
                  ) : null
                }
                {
                  twitterURL?.url ? (
                    <LinkWrapper target="_blank" url={twitterURL.url} aria-label="Twitter">
                      <IconTwitter />
                    </LinkWrapper>
                  ) : null
                }
                {
                  linkedinURL?.url ? (
                    <LinkWrapper target="_blank" url={linkedinURL.url} aria-label="LinkedIn">
                      <IconLinkedIn />
                    </LinkWrapper>
                  ) : null
                }
                {
                  instagramURL?.url ? (
                    <LinkWrapper className="social-btn" target="_blank" url={instagramURL.url} aria-label="Instagram">
                      <IconInstagram />
                    </LinkWrapper>
                  ) : null
                }
                {
                  pinterestURL?.url ? (
                    <LinkWrapper className="social-btn" target="_blank" url={pinterestURL.url} aria-label="Pinterest">
                      <IconPinterest />
                    </LinkWrapper>
                  ) : null
                }
              </div>
              <div className="copyright">
                <p className="legal-text">{ copyright }</p>
                <div className="legal-links">
                  {
                    termsOfServiceLink?.url ? (
                      <LinkWrapper url={termsOfServiceLink.url} onClick={closeMobileNav} aria-label="Terms of Service">
                        Terms &amp; Conditions
                      </LinkWrapper>
                    ) : null
                  }
                  {
                    privacyPolicyLink?.url ? (
                      <LinkWrapper url={privacyPolicyLink.url} onClick={closeMobileNav} aria-label="Privacy Policy">
                        Privacy Policy
                      </LinkWrapper>
                    ) : null
                  }
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
