/*globals process*/
import React, { createContext, useState, useEffect } from 'react';
// import Auth from '@aws-amplify/auth';
import checkSubscription from './check-subscription';

export function hideForVisibility ({ userData }, { visibility }) {
  return (visibility === 'Show to logged-in users only' && !userData) ||
    (visibility === 'Show to anonymous users only' && userData);
}

export const AuthContext = createContext({
  userData: null,
  subscription: null
});

export function AuthContextProvider ({ children }) {
  const [ userData, setUserData ] = useState(null);
  const [ subscription, setSubscription ] = useState(null);

  useEffect(() => {
    if (!process.env.GATSBY_ASSUME_SIGNED_IN) return;
    setUserData({});
    setSubscription({ active: true });
  }, []);

  useEffect(() => {
    if (userData) return;

    import('@aws-amplify/auth').then(({ Auth }) => {
      Auth.currentUserInfo().then((_userData) => {
        if (!_userData) return;

        setUserData(_userData);

        const { username } = _userData;

        window.dataLayer.push({ user_id: username });

        checkSubscription({ username }).then((_subscription) => {
          setSubscription(_subscription);

          window.dataLayer.push({
            subscription: _subscription?.active || false
          });
        });
      });
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider value={{ userData, subscription }}>
      {children}
    </AuthContext.Provider>
  );
}
