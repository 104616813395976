import React from 'react';
import { LinkWrapper } from '../link-wrapper';

const TextButton = ({ children, to, inverted, className, ...props }) => {
  if (!to) {
    return (
      <div
        className={`text-button ${inverted ? 'inverted' : ''} ${className}`}
        role="button"
        tabIndex="0"
        { ...props }
      >
        { children }
      </div>
    );
  }

  return (
    <LinkWrapper
      url={to}
      className={`text-button ${inverted ? 'inverted' : ''} ${className}`}
      { ...props }
    >
      { children }
    </LinkWrapper>
  );
};

export default TextButton;
