/* globals process */
import React, { useMemo, useContext } from 'react';
import { Link } from 'gatsby';
import { Img } from '../image';
import { LinkWrapper } from '../link-wrapper';
import { IconFacebook, IconLinkedIn, IconTwitter, IconInstagram, IconPinterest } from '../icons';
import NewsletterSignup from '../newsletter-signup';
import { AuthContext } from '../auth';
import './styles.scss';

function fixProdEnv (url) {
  if (!process.env.GATSBY_PROD_ENV) return url;
  return url.replace('.yogatherapylibrary.com',
    `.${process.env.GATSBY_PROD_ENV}.yogatherapylibrary.com`);
}

const Footer = ({
  logo,
  footerText,
  mainNav,
  extraFooterNav,
  copyright,
  termsOfServiceLink,
  privacyPolicyLink,
  twitterURL,
  facebookURL,
  linkedinURL,
  instagramURL,
  pinterestURL
}) => {
  const auth = useContext(AuthContext);

  const navRendered = useMemo(() => {
    if (!mainNav || !mainNav.length) return null;

    return mainNav.map((item, i) => {
      const {
        link,
        text
      } = item;

      return (
        <li
          key={`footer-nav-item-${i}`}
          className="footer-nav-item"
        >
          <LinkWrapper url={fixProdEnv(link.url)} target={link.target}>
            { text.text }
          </LinkWrapper>
        </li>
      );
    });
  }, [ mainNav ]);

  const extraFooterNavRendered = useMemo(() => {
    if (!extraFooterNav || !extraFooterNav.length) return null;

    return extraFooterNav.map((item, i) => {
      const {
        link,
        text
      } = item;

      return (
        <li key={i} className="footer-nav-item">
          <LinkWrapper url={fixProdEnv(link.url)} target={link.target}>{text}</LinkWrapper>
        </li>
      );
    });
  }, [ extraFooterNav ]);

  const loginNavItemRendered = useMemo(() => {
    return (
      <li className="footer-nav-item">
        <LinkWrapper url={auth.userData ? process.env.LOG_OUT_URL : process.env.LOG_IN_URL}>
          { auth.userData ? 'Log out' : 'Log in' }
        </LinkWrapper>
      </li>
    );
  }, [ auth ]);

  const footerTextRendered = useMemo(() => {
    if (!footerText?.text) return null;

    return (
      <div
        className="body-small footer-text"
        dangerouslySetInnerHTML={{ __html: footerText.html }}
      />
    );
  }, [ footerText ]);

  return (
    <footer className="site-footer">
      <div className="site-footer__inner container">
        <div className="logo-container">
          <Link to={'/'} aria-label="Yoga Therapy Library">
            <Img image={logo} />
          </Link>
        </div>
        <div className="footer-body">
          <div className="footer-nav-container">
            {footerTextRendered}
            <div className="divider" />
            <nav className="footer-nav">
              <ul className="nav-list">
                <li className="footer-nav-item">
                  <LinkWrapper url={'/'}>
                    Library
                  </LinkWrapper>
                </li>
                { navRendered }
                { loginNavItemRendered }
              </ul>
              <ul className="nav-list">
                { extraFooterNavRendered }
              </ul>
            </nav>
            <div className="copyright-container">
              {
                facebookURL?.url ? (
                  <LinkWrapper
                    className="social-btn"
                    target="_blank"
                    url={facebookURL.url}
                    aria-label="YTL Facebook"
                  >
                    <IconFacebook />
                  </LinkWrapper>
                ) : null
              }
              {
                twitterURL?.url ? (
                  <LinkWrapper
                    className="social-btn"
                    target="_blank"
                    url={twitterURL.url}
                    aria-label="YTL Twitter"
                  >
                    <IconTwitter />
                  </LinkWrapper>
                ) : null
              }
              {
                linkedinURL?.url ? (
                  <LinkWrapper
                    className="social-btn"
                    target="_blank"
                    url={linkedinURL.url}
                    aria-label="YTL LinkedIn"
                  >
                    <IconLinkedIn />
                  </LinkWrapper>
                ) : null
              }
              {
                instagramURL?.url ? (
                  <LinkWrapper
                    className="social-btn"
                    target="_blank"
                    url={instagramURL.url}
                    aria-label="YTL Instagram"
                  >
                    <IconInstagram />
                  </LinkWrapper>
                ) : null
              }
              {
                pinterestURL?.url ? (
                  <LinkWrapper
                    className="social-btn"
                    target="_blank"
                    url={pinterestURL.url}
                    aria-label="YTL Pinterest"
                  >
                    <IconPinterest />
                  </LinkWrapper>
                ) : null
              }
              <p className="legal-text">{copyright}</p>
              <LinkWrapper className="legal-link" url={fixProdEnv(termsOfServiceLink.url)} aria-label="Terms & Conditions">
                Terms &amp; Conditions
              </LinkWrapper>
              <LinkWrapper className="legal-link" url={fixProdEnv(privacyPolicyLink.url)} aria-label="Privacy Policy">
                Privacy Policy
              </LinkWrapper>
            </div>
          </div>
          <div className="sign-up-form">
            <NewsletterSignup
              heading={{ text: 'Let our yoga therapy resources come to you.' }}
              buttonText={{ text: 'Join Our Mailing List' }}
              id="footer-newsletter-signup"
            />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
