import React from 'react';

export function IconSearch () {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(1.5 1)" stroke="#E58061" strokeWidth="1.5" fill="none" fillRule="evenodd">
        <circle transform="matrix(-1 0 0 1 13.176 0)" cx="6.588" cy="6.588" r="6.588"/>
        <path strokeLinecap="round" d="M11.294 11.294 16 16"/>
      </g>
    </svg>
  );
}
