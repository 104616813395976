import React, { useState, useRef } from 'react';
import { Tooltip } from '@material-ui/core';
import { TextButton } from '../buttons';
import TextInput from '../text-input';
import './styles.scss';

export default function NewsletterSignup ({
  id,
  heading,
  buttonText
}) {
  const formRef = useRef(null);
  const [ email, setEmail ] = useState('');
  const [ inputInvalid, setInputInvalid ] = useState(false);
  const [ success, setSuccess ] = useState(false);

  const handleInputChange = (ev) => {
    setEmail(ev.target.value);

    if (inputInvalid) setInputInvalid(false);
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();

    if (!formRef.current.checkValidity()) return;

    const formData = new URLSearchParams({
      g: 'U9ThW9',
      '$fields': '$source',
      email,
      '$source': 'Footer Newsletter Signup'
    });

    const res = await fetch('https://manage.kmail-lists.com/ajax/subscriptions/subscribe', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        'cache-control': 'no-cache'
      },
      body: formData.toString()
    });

    if (!res.ok) {
      console.error(`Klaviyo error: ${res.status} ${await res.text()}`);
      return;
    }

    // {"data": {"is_subscribed": false, "email": "twarlick@maxmedia.com"}, "success": true, "errors": []}

    try {
      const resData = await res.json();

      if (resData.success) {
        console.info(`Klaviyo success: ${res.status} ${JSON.stringify(resData)}`);
        setSuccess(true);
      } else {
        console.error(`Klaviyo error: ${res.status} ${JSON.stringify(resData)}`);
        setSuccess(false);
      }
    } catch (e) {
      console.error(`Klaviyo error: ${res.status}`, e);
      setSuccess(false);
    }
  };

  return (
    <div className="newsletter-signup">
      {
        heading?.text ? (
          <h5 className="h5 form-heading">{heading.text}</h5>
        ) : null
      }
      <form className={success ? 'hide' : ''} ref={formRef} onSubmit={handleSubmit}>
        <Tooltip
          title="You must enter your email address"
          PopperProps={{ className: "newsletter-signup-error-tooltip" }}
          open={inputInvalid}
        >
          <TextInput
            handleChange={handleInputChange}
            value={email}
            label="Email Address"
            required
            type="email"
            onInvalid={() => setInputInvalid(true)}
            error={inputInvalid}
            id={`${id}-emailInput`}
          />
        </Tooltip>
      </form>
      <TextButton className={success ? 'hide' : ''} onClick={handleSubmit}>
        {buttonText.text}
      </TextButton>
      <p className={success ? 'success-msg' : 'hide'}>
        <strong>Thanks for subscribing!</strong>
        <br />Check your email for a confirmation message.
      </p>
    </div>
  );
}
