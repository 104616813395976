import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import Header from '../header';
import Footer from '../footer';
import useMergePrismicPreviewData from '../../utils/use-merge-prismic-preview-data-fix';
import './styles.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Layout = ({ children }) => {
  const _data = useStaticQuery(graphql`
    query HeaderQuery {
      prismicGlobalOptions {
        _previewable
        data {
          site_logo {
            gatsbyImageData(layout: FIXED, width: 300)
          }
          footer_logo {
            gatsbyImageData(layout: FIXED, width: 296)
          }
          footer_text { text, html }
          facebook_url {
            url
          }
          linkedin_url {
            url
          }
          twitter_url {
            url
          }
          instagram_url { url }
          pinterest_url { url }
          copyright
          privacy_policy_link {
            url
          }
          terms_of_service_link {
            url
          }
          subscribe_link {
            url
          }
          library_nav {
            text {
              text
            }
            link {
              url
            }
            link_logged_in { url }
          }
          main_nav {
            text {
              text
            }
            link {
              url
              target
            }
          }
          extra_footer_nav {
            text
            link { url, target }
          }
        }
      }
    }
  `);

  const data = useMergePrismicPreviewData(_data);

  const {
    site_logo,
    footer_logo,
    library_nav,
    main_nav,
    extra_footer_nav,
    facebook_url,
    twitter_url,
    linkedin_url,
    instagram_url,
    pinterest_url,
    copyright,
    privacy_policy_link,
    terms_of_service_link,
    footer_text,
    subscribe_link
  } = data.prismicGlobalOptions?.data;

  return (
    <div className="layout">
      <Helmet>
        <meta name="viewport"
          content="width=device-width, minimum-scale=1, initial-scale=1, maximum-scale=5" />
        <title>Yoga Therapy Library</title>
      </Helmet>
      <Header
        logo={site_logo}
        libraryNavItems={library_nav}
        mainNavItems={main_nav}
        extraFooterNav={extra_footer_nav}
        facebookURL={facebook_url}
        twitterURL={twitter_url}
        linkedinURL={linkedin_url}
        instagramURL={instagram_url}
        pinterestURL={pinterest_url}
        copyright={copyright}
        privacyPolicyLink={privacy_policy_link}
        termsOfServiceLink={terms_of_service_link}
        subscribeLink={subscribe_link}
      />
      <main>
        { children }
      </main>
      <Footer
        footerText={footer_text}
        logo={footer_logo}
        facebookURL={facebook_url}
        twitterURL={twitter_url}
        linkedinURL={linkedin_url}
        instagramURL={instagram_url}
        pinterestURL={pinterest_url}
        copyright={copyright}
        privacyPolicyLink={privacy_policy_link}
        termsOfServiceLink={terms_of_service_link}
        mainNav={main_nav}
        extraFooterNav={extra_footer_nav}
      />
    </div>
  );
};

export default Layout;
