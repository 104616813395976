import API from '@aws-amplify/api';

const ERROR_GRACE_PERIOD = 21600000; // 1 hour
const ACTIVE_RECHECK_INTERVAL = 7200000; // 2 hours
const OTHER_RECHECK_INTERVAL = 300000; // 5 minutes

async function getSubscriptionStatus () {
  const data = await API.get('sundara-ytl-api', '/subscription/status');

  return new Promise((resolve, reject) => {
    if (data.error) return reject(data);
    resolve(data);
  });
}

function getCurrent () {
  let current = sessionStorage.getItem('subscription');

  if (current) {
    current = JSON.parse(current);
    current.timestampMs = Date.parse(current?.timestamp ?? 0);
    //TODO validate signature
  }

  return current ?? null;
}

export default async function checkSubscription ({ username, force }={}) {
  const current = getCurrent();
  const currentDelta = Date.now() - (current?.timestampMs ?? 0);

  if (!force && username && current && current.username !== username) {
    return checkSubscription({ username, force: true });
  }

  if (
    !force && (
      ( current?.active && currentDelta > 0 && currentDelta < ACTIVE_RECHECK_INTERVAL ) ||
      ( !current?.active && currentDelta > 0 && currentDelta < OTHER_RECHECK_INTERVAL )
    )
  ) {
    return current;
  }

  let result;

  try {
    result = await getSubscriptionStatus();
  } catch (e) {
    console.error(e);

    if (currentDelta > 0 && currentDelta < ERROR_GRACE_PERIOD) return current;

    result = {
      ...current,
      active: false,
      status: 'error'
    };
  }

  result.username = username;

  sessionStorage.setItem('subscription', JSON.stringify(result));

  return result;
}
