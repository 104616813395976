import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import './styles.scss';

export function Img ({
  image,
  loading = 'lazy',
  placeholder = 'blurred',
  alt = '',
  ...props
}) {
  if (!image) return null;

  return (
    <GatsbyImage
      placeholder={placeholder}
      { ...props }
      image={getImage(image)}
      alt={alt}
      loading={loading}
    />
  );
}

export function BackgroundImage ({
  image,
  loading = 'lazy',
  placeholder = 'blurred',
  alt = '',
  ...props
}) {
  if (!image) return null;

  return (
    <div className="bg-img-container">
      <GatsbyImage
        role="presentation"
        alt={alt}
        placeholder={placeholder}
        { ...props }
        image={getImage(image)}
        loading={loading}
      />
    </div>
  );
}
